body {
  font-family: "Poppins", sans-serif !important;
  background-color: #d3d3d3;
}
body .container {
  max-width: 1440px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.subTitle {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}

.pageHeader {
  background-color: #fff;
  padding: 30px 0;
}
@media (min-width: 961px) {
  .pageHeader .menu > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .pageHeader .menu > ul > li {
    margin-left: 30px;
    position: relative;
  }
  .pageHeader .menu > ul > li:hover ul {
    visibility: visible;
    opacity: 1;
  }
  .pageHeader .menu > ul > li ul {
    position: absolute;
    padding: 0;
    width: 200px;
    padding-top: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
  }
  .pageHeader .menu > ul > li ul li {
    background-color: #f7f7f7;
  }
  .pageHeader .menu > ul > li ul li a {
    display: block;
    padding: 8px 15px;
  }
  .pageHeader .menu > ul > li a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.25s ease;
  }
  .pageHeader .menu > ul > li a:hover, .pageHeader .menu > ul > li a.active {
    color: #f4a213;
  }
}
.banner .imageSlide {
  min-height: 726px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
}
.banner .imageSlide .container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.banner .imageSlide .container .slideText {
  background-color: rgba(244, 162, 19, 0.7);
  max-width: 800px;
  align-self: flex-end;
  padding: 80px 90px;
  text-align: center;
}
.banner .imageSlide .container .slideText h3 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}
.banner .imageSlide .container .slideText p {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
  color: #fff;
}
.banner .imageSlide .container .slideText .btn {
  width: 175px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  /* Horizontally centers text */
  align-items: center;
  margin: 0 auto;
  background-color: #15253f;
  border-radius: 15px;
  font-size: 20px;
  border: none;
  transition: all 0.25s ease;
}
.banner .imageSlide .container .slideText .btn:hover {
  background-color: #2c0f0f;
}

.about {
  background-color: #fafafa;
  padding: 80px 0;
}
.about p {
  font-size: 18px;
}
@media (max-width: 767px) {
  .about .col-md-3 {
    text-align: center;
  }
}

.services {
  background-color: #fff;
  padding: 80px 0;
}
.services .boxElement {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  border: 2px solid #d3d3d3;
}
@media (max-width: 767px) {
  .services .boxElement {
    margin-bottom: 30px;
  }
  .services .boxElement .image {
    height: 300px;
    overflow: hidden;
  }
  .services .boxElement .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.services .boxElement .title {
  border-top: 2px solid #d3d3d3;
}
.services .boxElement .title p {
  padding: 12px 10px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.services .btn {
  width: 175px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  /* Horizontally centers text */
  align-items: center;
  margin: 0 auto;
  background-color: #15253f;
  border-radius: 15px;
  font-size: 20px;
  border: none;
  transition: all 0.25s ease;
}
.services .btn:hover {
  background-color: #2c0f0f;
}

.story {
  background: url("../images/storyBanner.webp") no-repeat center top;
  background-size: cover;
}
.story .container {
  min-height: 490px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.story .container .storyContainer {
  align-self: center;
}
.story .container .storyContainer h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}
.story .container .storyContainer p {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 20px;
}
.story .container .storyContainer .btn {
  width: 266px;
  min-height: 76px;
  display: flex;
  justify-content: center;
  /* Horizontally centers text */
  align-items: center;
  margin: 0 auto;
  background-color: #f4a213;
  border-radius: 30px;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  border: none;
  transition: all 0.25s ease;
}
.story .container .storyContainer .btn:hover {
  background-color: #ce8302;
}

.pageFooter {
  background-color: #2c0f0f;
  padding: 60px 0;
}
.pageFooter ul {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}
@media (max-width: 576px) {
  .pageFooter .footerTopMenu ul {
    flex-direction: column;
    margin-bottom: 15px;
  }
}
.pageFooter .footerTopMenu ul li {
  margin: 0 20px;
  position: relative;
}
.pageFooter .footerTopMenu ul li:not(:last-child):after {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #d5d5d5;
  right: -20px;
  top: 4px;
}
@media (max-width: 576px) {
  .pageFooter .footerTopMenu ul li:not(:last-child):after {
    content: inherit;
    display: none;
  }
}
@media (max-width: 576px) {
  .pageFooter .footerTopMenu ul li {
    margin: 5px 0;
  }
}
.pageFooter .footerTopMenu ul li a {
  font-size: 17px;
  color: #d5d5d5;
  font-weight: 500;
}
.pageFooter .socialMedia {
  padding: 25px 0;
}
.pageFooter .socialMedia ul li {
  margin: 0 8px;
}
.pageFooter .copyright p {
  margin-bottom: 0;
  color: #c6c6c6;
  font-size: 16px;
  font-weight: 500;
}

.innerBanner {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 0;
}
.innerBanner .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.innerBanner .container h2 {
  font-size: 35px;
  font-weight: 600;
  color: #fafafa;
  margin-bottom: 0;
}

.innerPageContent {
  min-height: 400px;
  padding: 40px 0;
}
.innerPageContent a {
  color: #15253f;
}
.innerPageContent a:hover {
  color: #ce8302;
}

#lightgallery a {
  margin-bottom: 25px;
  height: 200px;
  overflow: hidden;
  position: relative;
  padding: 0;
  margin: 0 12.5px 30px;
  width: calc(25% - 25px);
  border: 6px solid #f2f2f2;
  background: #fff;
  border-radius: 8px;
}
@media (max-width: 991px) {
  #lightgallery a {
    width: calc(33.33% - 25px);
  }
}
@media (max-width: 576px) {
  #lightgallery a {
    width: calc(50% - 25px);
  }
}
#lightgallery a:after {
  content: "";
  background-color: #2f0000;
  background-image: url("../images/zoomIcon.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  backface-visibility: hidden;
  transition: all 0.2s ease;
}
#lightgallery a:hover:after {
  opacity: 1;
  visibility: visible;
  background-color: rgba(47, 0, 0, 0.75);
}
#lightgallery a:hover img {
  transform: scale(1.5) rotate(8deg);
}
#lightgallery a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1) rotate(0deg);
  transition: transform 0.2s ease-in;
}

.googleMap iframe {
  width: 100% !important;
}

#video-gallery {
  padding: 0;
}
#video-gallery li a {
  position: relative;
  display: block;
  border: 6px solid #f2f2f2;
  background: #fff;
  border-radius: 8px;
}
#video-gallery li a .gallery-poster {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#video-gallery li a .gallery-poster img {
  width: 80px;
  height: 80px;
}

.servicePage .gridBox {
  border: 2px solid #c1c1c1;
  margin-bottom: 30px;
  padding: 4px;
  background: #e7e7e7;
}
.servicePage .title p {
  padding: 10px 8px;
  margin-bottom: 0;
}

.mobileMenu {
  display: none;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }

  .menu {
    display: none;
  }

  .mobileMenu {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-100%);
    /* Start off-screen (hidden) */
    transition: all 0.3s ease-in-out;
    /* Smooth slide-in and slide-out animation */
    z-index: 98;
  }
  .mobileMenu li {
    margin: 10px 0;
  }
  .mobileMenu li a {
    font-size: 20px;
    color: #d5d5d5;
  }
  .mobileMenu li ul {
    padding-left: 20px;
  }

  .mobileMenu.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    /* Slide into view */
  }

  .mobileMenu {
    position: fixed;
    background-color: #2c0f0f;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .mobileMenu ul {
    padding: 0;
    margin: 0;
  }

  #nav-icon2 {
    width: 60px;
    height: 45px;
    position: relative;
    /* margin: 50px auto; */
    margin-left: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon2 {
    z-index: 99;
    transform: scale(0.6);
  }

  #nav-icon2 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 50%;
    background: #d3531a;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon2 span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  #nav-icon2 span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
  }

  #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
    top: 0px;
  }

  #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
    top: 18px;
  }

  #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
    top: 36px;
  }

  #nav-icon2.open span:nth-child(1), #nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon2.open span:nth-child(2), #nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon2.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  #nav-icon2.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  #nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #nav-icon2.open span:nth-child(5) {
    left: 5px;
    top: 29px;
  }

  #nav-icon2.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
  }

  body {
    transition: overflow 0.3s ease-in-out;
    /* Smooth transition for body overflow */
  }
}